import React, { useState } from "react";
import Layout from "../components/Layout";
import HeroSection from "../components/HeroSection";
import * as styles from "../styles/blog.module.css";
import * as heroStyles from "../styles/hero-section.module.css";
import { FaSearch } from "react-icons/fa";
import { graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";
import LinearReveal from "../components/animation/LinearReveal";

export default function Blog({ data, location }) {
  //  Storing blogs data in a variable
  const allBlogs = data.allMarkdownRemark.edges;
  // Variable to set tag
  const [tag, settag] = useState(location.state ? location.state.tag : "");

  // Blogs to be shown
  const [blogs, setBlogs] = useState(
    allBlogs.filter((val) => {
      if (tag === "") {
        return val.node.frontmatter;
      } else {
        return val.node.frontmatter.tag.includes(tag);
      }
    })
  );

  // Sort blog by searchbar
  const sortResBySearch = (e) => {
    if (e) {
      setBlogs(
        allBlogs.filter((val) => {
          if (e === "") {
            return val.node.frontmatter;
          } else {
            if (tag === "") {
              return val.node.frontmatter.title.toLowerCase().includes(e.toLowerCase());
            } else {
              return (
                val.node.frontmatter.tag.includes(tag) &&
                val.node.frontmatter.title.toLowerCase().includes(e.toLowerCase())
              );
            }
          }
        })
      );
    } else {
      sortResByBtn(tag);
    }
  };

  // Sort blogs by button
  const sortResByBtn = (e) => {
    settag(e);
    setBlogs(
      allBlogs.filter((val) => {
        if (e === "") {
          return val.node.frontmatter;
        } else {
          return val.node.frontmatter.tag.includes(e);
        }
      })
    );
  };

  return (
    <Layout>
      {/* Meta tags */}
      <Helmet>
        <title>Blog - DBT</title>
        <meta
          name="description"
          content="DBT Blog. The future of communication starts here"
        />
      </Helmet>

      {/* Top image section */}
      <HeroSection
        title="DBT Blog"
        titleClass={"text-primary " + heroStyles.heroTitle}
        description="The future of communication starts here"
        descClass={"has-text-white-bis " + heroStyles.heroDesc}
        imageClass={heroStyles.blogImage + " " + heroStyles.heroData}
        divClass={heroStyles.divClass}
      />
      <section id="content" className={styles.content + " has-background-primar"}>
        {" "}
        {/* Search bar */}
        <div className="field is-flex is-justify-content-center">
          <div className="control has-icons-right mt-2" style={{ width: "700px" }}>
            <input
              className={"input " + styles.input}
              type="text"
              required
              id="name"
              placeholder="Search for blog"
              onInput={(e) => {
                sortResBySearch(e.target.value);
              }}
            />
            <span className="icon is-right">
              <FaSearch />
            </span>
          </div>
        </div>
        {/* Sorting Buttons */}
        <div
          className={
            "is-flex is-justify-content-space-between is-flex-wrap-wrap mt-2 " +
            styles.tagButtons
          }
        >
          <input
            type="radio"
            name="tag"
            id="All"
            defaultChecked={tag === ""}
            className={styles.tagBtn}
            hidden
          />
          <label
            htmlFor="All"
            onClick={() => {
              sortResByBtn("");
            }}
            aria-hidden="true"
            className={styles.sortBtnLabel}
          >
            All
          </label>
          <input
            type="radio"
            name="tag"
            id="Salesforce"
            defaultChecked={tag === "Salesforce"}
            className={styles.tagBtn}
            hidden
          />
          <label
            htmlFor="Salesforce"
            onClick={() => {
              sortResByBtn("Salesforce");
            }}
            aria-hidden="true"
            className={styles.sortBtnLabel}
          >
            Salesforce
          </label>{" "}
          <input
            type="radio"
            name="tag"
            id="SAP"
            defaultChecked={tag === "SAP"}
            className={styles.tagBtn}
            hidden
          />
          <label
            htmlFor="SAP"
            onClick={() => {
              sortResByBtn("SAP");
            }}
            aria-hidden="true"
            className={styles.sortBtnLabel}
          >
            SAP
          </label>{" "}
          <input
            type="radio"
            name="tag"
            id="Data Engineering"
            defaultChecked={tag === "Data Engineering"}
            className={styles.tagBtn}
            hidden
          />
          <label
            htmlFor="Data Engineering"
            onClick={() => {
              sortResByBtn("Data Engineering");
            }}
            aria-hidden="true"
            className={styles.sortBtnLabel}
          >
            Data Engineering
          </label>{" "}

          <input
            type="radio"
            name="tag"
            id="AI"
            defaultChecked={tag === "AI"}
            className={styles.tagBtn}
            hidden
          />
          <label
            htmlFor="AI"
            onClick={() => {
              sortResByBtn("AI");
            }}
            aria-hidden="true"
            className={styles.sortBtnLabel}
          >
            AI
          </label>

        </div>
        {/* Drop down menu for mobile */}
        <div className={"has-text-centered mt-2 " + styles.tagDropdown}>
          <div className="select">
            <select
              defaultValue={tag}
              onChange={(e) => {
                sortResByBtn(e.target.value);
              }}
              onBlur={(e) => {
                sortResByBtn(e.target.value);
              }}
            >
              <option value="">All</option>
              <option value="Salesforce">Salesforce</option>
              <option value="SAP">SAP</option>
              <option value="Data Engineering">Data Engineering</option>
              <option value="AI">AI</option>
            </select>{" "}
          </div>
        </div>
        {/* Display all blogs */}
        <hr />
        {blogs.length !== 0 ? (
          <div className={styles.blogContainer}>
            {blogs.map((blog) => (
              <Link
                to={"/blog" + blog.node.fields.slug}
                key={blog.node.id}
                className={styles.blogCard}
                state={{ tag: tag }}
              >
                <LinearReveal>
                  <div className={"card "}>
                    <div
                      className={"is-flex is-justify-content-center " + styles.blogImg}
                    >
                      <GatsbyImage
                        image={getImage(blog.node.frontmatter.thumbnail.childImageSharp)}
                        alt={blog.node.frontmatter.title}
                      />
                    </div>

                    <div className="card-content ">
                      <div className="content">
                        <div
                          className={
                            "has-text-weight-semibold is-size-5 " + styles.blogTitle
                          }
                        >
                          {blog.node.frontmatter.title}
                        </div>
                      </div>
                      <div className="is-flex is-justify-content-space-between has-text-grey ">
                        <div>{blog.node.frontmatter.date}</div>
                        <div>
                          {blog.node.frontmatter.videoURL
                            ? "Video"
                            : blog.node.frontmatter.readTime
                            ? blog.node.frontmatter.readTime + " min read"
                            : "5 min read"}
                        </div>
                      </div>
                      <div className="is-size-7 is-flex is-flex-direction-row is-flex-wrap-wrap">
                        {blog.node.frontmatter.tag.map((tag) => (
                          <div key={tag}>#{tag}&nbsp;</div>
                        ))}
                      </div>
                    </div>
                  </div>
                </LinearReveal>
              </Link>
            ))}{" "}
          </div>
        ) : (
          <div className="mt-5 has-text-bold has-text-centered ">
            More blogs will be added soon.
          </div>
        )}
      </section>
    </Layout>
  );
}
export const query = graphql`
  query DisplayAllBlogs {
    allMarkdownRemark(sort: { fields: frontmatter___date, order: DESC }) {
      edges {
        node {
          frontmatter {
            title
            videoURL
            readTime
            tag
            date(formatString: "MMMM DD YYYY")
            thumbnail {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          html
          fields {
            slug
          }
          id
        }
      }
    }
  }
`;
